import { ServiceFactory } from '@/services/ServiceFactory'
const _usersService = ServiceFactory.get('UsersService')

export default {
  // components: {
  //   ZbNotificationsChart
  // },
  name: 'ZbUsers',
  data: () => ({
    headers: [
      { text: 'Nombre', value: 'name', align: 'start', sortable: false },
      { text: 'Email', value: 'email' },
      { text: 'Nombre de usuario', value: 'username' },
      { text: 'Celular', value: 'cellphone' },
      { text: 'Fecha de nacimiento', value: 'birthDate' },
      { text: 'Opciones', value: 'options' }
      // { text: '', value: 'data-table-expand' }
    ],
    rootUsers: []
  }),
  mounted () {
    this.initialize()
  },
  methods: {
    goToForm () {
      this.$router.push({ name: 'users-form' })
    },
    editUser (user) {
      console.log(user)
      this.$router.push({ name: 'users-form', params: { user } })
    },
    async initialize () {
      try {
        const usersResponse = await _usersService.index()
        console.log(usersResponse)
        this.rootUsers = usersResponse.rootUsers
      } catch (error) {
        console.log(error)
      }
    },
    async enableUser (item) {
      console.log(item)
      try {
        this.$swal({
          title: '¿Desea habilitar al usuario(a) "' + item.name + '"?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sí',
          cancelButtonText: 'Cancelar'
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const response = await _usersService.enable(item.zbUserId)
              console.log(response)
              // this.loading2 = false
              this.$swal(
                'Usuario habilitado',
                response.msg,
                'success'
              ).then(async (result) => {
                this.initialize()
              })
            } catch (error) {
              // this.loading2 = false
              this.$notify({
                title: 'Error:',
                type: 'error',
                text: error.msg
              })
            }
          } else {
            // this.loading2 = false
          }
        })
      } catch (error) {
        console.log(error)
        this.$notify({
          type: 'error',
          text: 'Algo salió mal al habilitar el usuario, vuelva a intentarlo nuevamente'
        })
      }
    },
    async disableUser (item) {
      console.log(item)
      try {
        this.$swal({
          title: '¿Desea deshabilitar al usuario(a) "' + item.name + '"?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sí',
          cancelButtonText: 'Cancelar'
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const response = await _usersService.destroy(item.zbUserId)
              console.log(response)
              // this.loading2 = false
              this.$swal(
                'Usuario deshabilitado',
                response.msg,
                'success'
              ).then(async (result) => {
                this.initialize()
              })
            } catch (error) {
              // this.loading2 = false
              this.$notify({
                title: 'Error:',
                type: 'error',
                text: error.msg
              })
            }
          } else {
            // this.loading2 = false
          }
        })
      } catch (error) {
        console.log(error)
        this.$notify({
          type: 'error',
          text: 'Algo salió mal al deshabilitar el usuario, vuelva a intentarlo nuevamente'
        })
      }
    }
  }
}
